<template>
  <div class='h-full w-full'>
    <slot></slot>
    <sidepanel><portal-target name='sidepanel'></portal-target></sidepanel>
    <modal><portal-target name='modals'></portal-target></modal>
    <overlay @click.native='closeContent' />
    <loading-indicator v-if='showMainPageLoadingIndicator' />
  </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import Modal            from '@/components/Modal.vue'
import Overlay          from '@/components/Overlay.vue'
import Sidepanel        from '@/components/Sidepanel.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'MobileAppWebviewLayout',
  components: {
    LoadingIndicator,
    Modal,
    Overlay,
    Sidepanel,
  },
  computed: {
    ...mapState([
      'showModal',
      'showPopover',
      'showSidepanel',
    ]),
    ...mapGetters([
      'showMainPageLoadingIndicator',
    ]),
    isReadyWithToken () {
      return this.$auth.isAuthenticated && !this.$auth.loading
    },
  },
  methods: {
    ...mapActions([
      'modalClose',
      'sidepanelClose',
    ]),
    ...mapMutations([
      'setShowPopover'
    ]),
    closeContent () {
      // refactor this so there is an order, where top stuff comes first.... modal - navmenu - sidepanel
      if (this.showModal) {
        this.modalClose()
      } else if (this.showPopover) {
        this.setShowPopover(false)
      } else if (this.showSidepanel) {
        this.sidepanelClose()
      }
    },
  },
  mounted () {
    window.addEventListener("message", (event) => {
      if (event.data === 'backTapped') {
        this.closeContent()
      }
    })
  }
}
</script>
